import React from 'react'
import {graphql,useStaticQuery} from 'gatsby'
import Layout from '../components/layout'
import ProjectsPage from '../pages/projects'
import projectStyles from './template.module.scss'
import Head from '../components/head'

import { documentToReactComponents} from '@contentful/rich-text-react-renderer'
export const query = graphql`
query($slug:String!){
    contentfulProjectPost(slug:{eq:$slug}){
        title
        role
        descriptionAndMedia{
            json
        }
       duties{
           json
       }
       mediaDescription{
           json
       }
    }
}
`

const Project = (props) =>{
    const options={
        renderNode:{
            "embedded-asset-block":(node)=>{
                const alt= node.data.target.fields.title['en-US']
                const url = node.data.target.fields.file['en-US'].url
                return <img alt ={alt} src={url}/>
                
            }
        }
    }
    
    return(
        <Layout>
            <Head title={props.data.contentfulProjectPost.title}/>
            <div className={projectStyles.mainContent}>
                <div className={projectStyles.rowContent}>
                    {documentToReactComponents(props.data.contentfulProjectPost.mediaDescription.json,options)}
                </div>
                <div className={projectStyles.rowContentRight}>
                    <h3>{props.data.contentfulProjectPost.title}</h3>
                    <p>{props.data.contentfulProjectPost.role}</p>
                                        
                    {documentToReactComponents(props.data.contentfulProjectPost.descriptionAndMedia.json,options)}
                    {documentToReactComponents(props.data.contentfulProjectPost.duties.json,options)}
                </div>
               

            </div>
            
           
        </Layout>
    )
}
//{documentToReactComponents(props.data.contentfulProjectPost.duties.json)}

export default Project